.rodape {
    background-color: #000000;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    gap: 20px;
    color: rgb(255, 255, 255); /* Cor do texto */
}

/* Centralizar todos os elementos no mobile */
@media (max-width: 768px) {
    .rodape {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.rodape-info,
.rodape-departamentos,
.rodape-contato,
.rodape-pagamento-envio {
    flex: 1;
    min-width: 200px;
}

.rodape-info {
    text-align: center;
}

.rodape-info h1 {
    margin: 0;
    font-family: 'Poppins', sans-serif; 
}

/* Estilizando <p> com a fonte Poppins */
.rodape p {
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.rodape-departamentos h3,
.rodape-contato h3,
.rodape-pagamento-envio h3 {
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif; 
}

.rodape-departamentos ul {
    list-style: none;
    padding: 0;
    font-family: 'Poppins', sans-serif; 
}

.rodape-departamentos ul li {
    margin: 5px 0;
    font-family: 'Poppins', sans-serif; 
}

.rodape-departamentos ul li a {
    text-decoration: none;
    color: rgb(255, 255, 255); /* Cor dos links */
}

.rodape-pagamento-envio {
    display: flex;
    flex-direction: column;
}

.rodape-pagamento-envio .bandeiras-pagamento,
.rodape-pagamento-envio .bandeiras-envio {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
}

/* Removendo a cor preta dos ícones para que apareçam coloridos */
.bandeiras-pagamento svg,
.bandeiras-envio svg {
    font-size: 24px;
    color: inherit; /* Herda a cor natural do ícone */
}

.rodape-logo {
    max-width: 60px;
    margin-bottom: 10px;
}
