.categorias {
    position: relative;
  }
  
  .menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .icon {
    font-size: 30px;
    color: #fff; /* Ícone na cor branca */
  }
  
  .categorias-dropdown {
    position: absolute;
    top: 10px; /* Ajuste conforme necessário */
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    z-index: 1000; /* Para garantir que esteja acima de outros conteúdos */
  }
  
  .categorias-dropdown ul {
    list-style-type: none;
    padding: 10px;
    margin: 0;
  }
  
  .categorias-dropdown li {
    margin-bottom: 1px;
  }
  
  .categorias-dropdown li a {
    color: #000000; /* Cor preta para o texto das categorias */
    text-decoration: none;
    font-family: 'Roboto', sans-serif; /* Fonte Poppins */
    font-weight: bold;
  }
  
  .categorias-dropdown li a:hover {
    text-decoration: underline;
  }
  