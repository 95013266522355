.produto-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.produto-detalhes {
  display: flex;
  gap: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.produto-imagens-adicionais {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.produto-imagem-maior {
  margin-bottom: 20px;
  text-align: center;
}

.produto-imagem {
  max-width: 100%;
  max-height: 600px;
  object-fit: cover;
  border-radius: 10px;
}

.produto-imagem-pequena {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.produto-imagem-pequena.active,
.produto-imagem-pequena:hover {
  border-color: #000000;
}

.produto-imagem-e-descricao {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.produto-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.produto-info h1 {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.produto-info .preco {
  font-size: 28px;
  color: #c4f40a;
  font-weight: bold;
  margin-bottom: 20px;
}

.add-to-cart-btn {
  padding: 15px 30px;
  background-color: #c4f40a;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
  margin-bottom: 20px;
}

.add-to-cart-btn:hover {
  background-color: #abd115;
  transform: scale(1.05);
}

.formas-pagamento {
  margin-top: 20px;
}

.formas-pagamento p {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.icones-pagamento {
  display: flex;
  gap: 15px;
}

.icone-pagamento {
  font-size: 36px;
  color: #333;
}

.tamanhos-disponiveis {
  margin-top: 20px;
  margin-bottom: 40px;
}

.tamanhos-disponiveis h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.tamanhos {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  justify-content: center;
  overflow-x: auto;
}

.tamanho {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
}

.tamanho.selected {
  background-color: #c4f40a;
  color: #fff;
  border: 1px solid #c4f40a;
}

.tamanho.indisponivel {
  text-decoration: line-through;
  color: grey;
  pointer-events: none;
}

/* Estilos para desktop */
.produto-descricao {
  order: 2; /* A descrição deve aparecer abaixo da imagem */
  color: #000000;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: left;
  width: 100%;
}

/* Estilos para mobile */
/* Estilos para mobile */
@media only screen and (max-width: 768px) {
  .produto-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .produto-detalhes {
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }

  .produto-imagens-adicionais {
    flex-direction: row;
    gap: 5px;
    overflow-x: auto;
    justify-content: center;
    width: 100%;
  }

  .produto-imagem-pequena {
    width: 60px;
    height: 60px;
  }

  /* No mobile, a imagem fica no topo */
  .produto-imagem-e-descricao {
    order: 1; /* A imagem vai para o topo */
  }

  /* As informações do produto vêm logo após a imagem */
  .produto-info {
    order: 2; /* Informações de pagamento e tamanhos logo após a imagem */
    width: 100%;
  }

  /* A descrição vai para o final */
  .produto-descricao {
    order: 3; /* Descrição no final, abaixo das informações e pagamento */
    text-align: left;
    margin-top: 10px;
  }
}
