/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #000;
}

.app {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000000;;
  color: #fff;
}

.logo {
  height: 90px;
  display: flex;
  align-items: center; /* Garante que a logo esteja centralizada verticalmente */
}


.nav {
  display: flex;
  align-items: center; /* Garante que todos os itens dentro de .nav estejam alinhados ao centro */
}

.menu {
  list-style: none;
  display: flex;
}

.menu li {
  margin: 0 10px;
}

.menu a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Poppins', sans-serif; 
}

.icons {
  display: flex;
  align-items: center; /* Alinha os ícones verticalmente ao centro */
  gap: 10px; /* Espaçamento entre os ícones */
}


.icons a {
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  display: flex;
  align-items: center; /* Alinha os ícones verticalmente ao centro */
   justify-content: center;
}

.menu-icon {
  display: none; /* Isso pode ser ajustado para dispositivos móveis */
  margin-top: 0px;
  padding: 0;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

.banner {
  width: 100%;
  max-height: 700px; /* Ajuste para controlar a altura máxima do banner */
  object-fit: cover;  /* Para garantir que a imagem se ajuste bem dentro da área definida */
  margin-top: 0,1px;
}

.products-title {
  font-size: 28px;
  margin: 40px 0 20px;
  color: #000000;
  font-weight: bold;
  font-family: 'Poppins', sans-serif; 
}


.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas de tamanho igual */
  gap: 20px; /* Espaçamento entre os itens */
  justify-content: center; /* Centraliza a grid */
  max-width: 1200px; /* Largura máxima do container */
  margin: 0 auto; /* Centraliza horizontalmente */
  padding: 20px;
}



.product-item {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 200px; /* Limita a altura da imagem */
  object-fit: cover; /* Mantém o aspecto da imagem */
  border-radius: 8px;
}

.product-item h3 {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-family: 'Poppins', sans-serif; 
}
.product-item p {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
}

.product-item .price {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif; 
}
.cart-icon {
  background: none;
  border: none;
  font-size: 24px; /* Tamanho dos ícones */
  color: #ffffff;
  cursor: pointer;
  padding: 0; /* Remove o padding padrão */
  margin: 0; /* Remove o margin padrão */
   margin-top: 5px;
}

/* Estilos para o segundo banner */
.banner2 {
  width: 100%;
  max-height: 700px; /* Ajuste a altura para um banner médio */
  object-fit: cover;
  margin-top: 30px; /* Espaço entre a listagem de produtos e o segundo banner */
}

/* Estilos para as seções de informações com ícones */
.info-sections {
  display: flex;
  justify-content: space-around;
  margin: 40px 0; /* Espaçamento acima e abaixo das seções de informações */
}

.info-item {
  text-align: center;
  max-width: 200px;
}

.info-icon {
  font-size: 40px;
  color: #c4f40a;
  margin-bottom: 10px;
}

.info-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
}
.categorias {
  display: none;
}
.info-item p {
  font-size: 16px;
  color: #666;
  font-family: 'Poppins', sans-serif; 
}

/* Estilos para mobile */
@media only screen and (max-width: 768px) {
  /* Header - Logo e Menu */
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center; /* Centraliza verticalmente os itens do cabeçalho */
    padding: 5px 10px;
  }

  .logo {
    height: 60px; /* Reduz o tamanho do logo */
  }

  .nav {
    flex-direction: row;
    align-items: center;
    justify-content: space-around; /* Espaça os itens do menu */
  }

  .categorias {
    display: block;
  }

  /* Esconde as categorias na barra de navegação */
  .nav .menu {
    display: none;
  }

  .menu {
    display: none; /* Esconde as categorias na barra de navegação principal */
  }

  .menu li {
    margin: 0 5px; /* Reduz o espaço entre os itens */
  }

  /* Ícones */
  .icons {
    display: flex;
    flex-direction: row;
    align-items: center; /* Alinha verticalmente os ícones */
    justify-content: space-between; /* Espaça horizontalmente os ícones */
    gap: 6px; /* Espaçamento entre os ícones */
    margin-top: 0;
  }

  /* Ajuste o tamanho dos ícones */
  .icons a {
  
    font-size: 24px; /* Tamanho dos ícones */
    line-height: 1;
    display: flex;
    align-items: center; /* Centraliza verticalmente os ícones */
    justify-content: center; /* Centraliza horizontalmente os ícones */
  }
/* Banner */
  .banner {
    content: url('./IMG/banner3.png');
    width: 100%;
    height: auto;
  }

  /* Destaques - Produtos */
  .product-list {
    grid-template-columns: repeat(2, 1fr); /* 2 colunas para dispositivos móveis */
    gap: 10px; /* Espaçamento reduzido */
  }

  .product-item {
    margin-bottom: 10px;
  }

  /* Seções de Informações */
  .info-sections {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .info-item {
    flex: 1;
    max-width: 120px;
    margin: 0 5px;
  }

  .info-icon {
    font-size: 24px;
  }

  .info-item h3 {
    font-size: 16px;
  }

  .info-item p {
    font-size: 14px;
  }

  /* Rodapé */
  .rodape {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
  }

  .rodape div {
    flex: 1 1 45%;
    margin: 5px;
    font-size: 14px;
  }
}

